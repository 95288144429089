<template>
  <div class="content">
    <h1 class="title is-1">
      Page not found.
    </h1>
    <p>
      We could not find the requested URL. You can go to
      <router-link class="navbar-item" :to="{ name: 'Home' }">Home</router-link>
      instead.
    </p>
  </div>
</template>

<script>
export default {};
</script>
